export function formatDate (date: string | null): string | null {
  if (!date) return null
  const [year, month, day] = date.split('-')
  return `${year}年${month}月${day}日`
}

export function formatDateWithoutYear (date: string | null): string | null {
  if (!date) return null
  const dates: string[] = date.split('-')
  return `${dates[1]}月${dates[2]}日`
}
